import { RecordAuthMode, RecordAuthState, RecordAuthUser, RECORD_AUTH_MODE, RECORD_AUTH_STATE, RECORD_AUTH_USER } from './types';

export function recordAuthState(authState: String): RecordAuthState {
    return {
        type: RECORD_AUTH_STATE,
        payload: authState
    }
};

export function recordAuthMode(authMode: String): RecordAuthMode {
    return {
        type: RECORD_AUTH_MODE,
        payload: authMode
    }
};

export function recordAuthUser(authUser: any): RecordAuthUser {
    return {
        type: RECORD_AUTH_USER,
        payload: authUser
    }
};