import variables from '../assets/variables.module.scss';

export const LoyaltyRoverTheme = {
    a: {
        color: variables.roverBlue,
    },
    button: {
        background: variables.btnPrimary,
    },
    formContainer: {
        margin: "0px"
    },
    formRow: {
        display: "flex",
        flexDirection: "row-reverse"
    },
    formSection: {
        maxWidth: "80%",
        margin: "auto",
    },
    input: {
        border: `1px solid ${variables.darkStroke}`,
        fontSize: "24px",
    },
    inputLabel: {
        display: "none",
    },
    label: {
        minWidth: "30%",
        maxWidth: "45%",
    },
    radio: {
        margin: "auto"
    },
    signInButton: {
        background: variables.lightTheme,
        color: variables.txtMain,
        textTransform: "none",
        fontSize: "18px",
        border: "none",
        boxShadow: `0px 0.5px 1px 1px ${variables.lightStroke}`
    },
    strike: {
        color: variables.darkStroke,
        borderColor: variables.darkStroke
    }
};