import './styles.scss';
import React from 'react';
import Select from 'react-select';
import { ProgramMembership, RewardProgram } from '../../generated/graphql';
import styleVars from './../../assets/variables.module.scss';
import { ProgramOption } from './ProgramOption';
import { MembershipRow } from './MembershipRow';

export const UpdateProgramsForm: React.FunctionComponent<any> = ({ programs, memberships }) =>{

    const selectStyles = {
        container: (provided: any, state: any) => ({
            ...provided,
            maxWidth: "80%",
            margin: "auto",
            background: styleVars.lightTheme,
            boxSizing: "border-box",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "4px",
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: "1px solid hsl(0,0%,80%)",
            boxShadow: state.isFocused ? 0 : "0px 4px 4px rgba(0, 0, 0, 0.25)",
            '&:hover': {
               border: "1px solid hsl(0,0%,80%)"
            }
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
            display: "flex",
            justifyContent: "space-evenly",
            width: "75%",
            fontSize: "16px",
            color: styleVars.txtLight
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            display: "none",
        })
    };

    return (
        <>
            <div>
                <Select 
                    classNamePrefix="react-select"
                    styles={selectStyles}
                    placeholder="Search Loyalty Programs"
                    components={{ Option: ProgramOption }}
                    options={programs.map((program: RewardProgram) => ({value: program.id, label: program.name}))}
                    />
            </div>
            <div className="memberships-container">
                { memberships.length === 0
                    ? <p>Add your <br /> loyalty programs</p>
                    : memberships.map(({ id, rewardProgram }: ProgramMembership) => {
                        let programName = (rewardProgram && rewardProgram.name)? rewardProgram.name : "";
                        return <MembershipRow key={programName} membershipId={id} programName={programName} />
                    })
                }
            </div>
        </>
    )
}