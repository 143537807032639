import { takeLatest, call } from 'redux-saga/effects';
import { init } from '../store/init';
import { RecordAuthState, RECORD_AUTH_STATE } from '../store/auth';

function* initApp(authStateAction: RecordAuthState) {

    if(authStateAction.payload === "signedIn") {
        try {
            yield call(init)
         } catch (error) {
             console.error("An error occurred in initAppSaga: ", error);
         }
    }
}

export function* initAppSaga() {
    //@ts-ignore
    yield takeLatest(RECORD_AUTH_STATE, initApp);
}