import { Reducer } from 'redux';
import { ProgramAction, STORE_PROGRAMS } from "./types";
import { RewardProgram } from '../../generated/graphql';

let initialState: RewardProgram[] = [];

export const programsReducer: Reducer<RewardProgram[], ProgramAction> = (
    state = initialState, 
    action: ProgramAction
): RewardProgram[] => {
    switch (action.type) {
        case STORE_PROGRAMS:
            return action.payload;
        default:
            return state;
    }
};