import { Reducer } from 'redux';
import { AuthAction, RECORD_AUTH_MODE, RECORD_AUTH_STATE, RECORD_AUTH_USER } from './types';

let initialState = {};

export const authReducer: Reducer<any, AuthAction> = (
    state = initialState, 
    action: AuthAction
): any => {
    switch (action.type) {
        case RECORD_AUTH_STATE:
            return {
                ...state,
                state: action.payload
            };
        case RECORD_AUTH_MODE:
            return {
                ...state,
                mode: action.payload
            }
        case RECORD_AUTH_USER:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
};