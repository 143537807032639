import { Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import Store from './../store';
import { recordAuthMode, recordAuthUser } from '../store/auth';

export class AuthUtil {

    static async determineAuthMode(): Promise<GRAPHQL_AUTH_MODE> {

        let authMode = GRAPHQL_AUTH_MODE.AWS_IAM;

        await Auth.currentAuthenticatedUser()
            .then(user => {
                authMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
            })
            .catch(error => {
                authMode = GRAPHQL_AUTH_MODE.AWS_IAM;
            });

        Store.dispatch(recordAuthMode(authMode));
        
        return authMode;
    }

    static async determineCurrentUser(): Promise<any> {
        let retrievedUser;
        await Auth.currentAuthenticatedUser()
            .then(user => {
                retrievedUser = user;
            }).catch(error => {
                retrievedUser = null;
            });

        Store.dispatch(recordAuthUser(retrievedUser));

        return retrievedUser;
    }
}