/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRewardProgram = /* GraphQL */ `
  mutation CreateRewardProgram(
    $input: CreateRewardProgramInput!
    $condition: ModelRewardProgramConditionInput
  ) {
    createRewardProgram(input: $input, condition: $condition) {
      id
      name
      providerId
      baseRate
      additionalInfo
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateRewardProgram = /* GraphQL */ `
  mutation UpdateRewardProgram(
    $input: UpdateRewardProgramInput!
    $condition: ModelRewardProgramConditionInput
  ) {
    updateRewardProgram(input: $input, condition: $condition) {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteRewardProgram = /* GraphQL */ `
  mutation DeleteRewardProgram(
    $input: DeleteRewardProgramInput!
    $condition: ModelRewardProgramConditionInput
  ) {
    deleteRewardProgram(input: $input, condition: $condition) {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createProgramPartner = /* GraphQL */ `
  mutation CreateProgramPartner(
    $input: CreateProgramPartnerInput!
    $condition: ModelProgramPartnerConditionInput
  ) {
    createProgramPartner(input: $input, condition: $condition) {
      id
      rewardProgramId
      partnerId
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      partner {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateProgramPartner = /* GraphQL */ `
  mutation UpdateProgramPartner(
    $input: UpdateProgramPartnerInput!
    $condition: ModelProgramPartnerConditionInput
  ) {
    updateProgramPartner(input: $input, condition: $condition) {
      id
      rewardProgramId
      partnerId
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      partner {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteProgramPartner = /* GraphQL */ `
  mutation DeleteProgramPartner(
    $input: DeleteProgramPartnerInput!
    $condition: ModelProgramPartnerConditionInput
  ) {
    deleteProgramPartner(input: $input, condition: $condition) {
      id
      rewardProgramId
      partnerId
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      partner {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $input: CreateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    createProvider(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      partnerPrograms {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $input: UpdateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    updateProvider(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      partnerPrograms {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider(
    $input: DeleteProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    deleteProvider(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      partnerPrograms {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createProgramMembership = /* GraphQL */ `
  mutation CreateProgramMembership(
    $input: CreateProgramMembershipInput!
    $condition: ModelProgramMembershipConditionInput
  ) {
    createProgramMembership(input: $input, condition: $condition) {
      id
      rewardProgramId
      userId
      status
      pointBalance
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateProgramMembership = /* GraphQL */ `
  mutation UpdateProgramMembership(
    $input: UpdateProgramMembershipInput!
    $condition: ModelProgramMembershipConditionInput
  ) {
    updateProgramMembership(input: $input, condition: $condition) {
      id
      rewardProgramId
      userId
      status
      pointBalance
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteProgramMembership = /* GraphQL */ `
  mutation DeleteProgramMembership(
    $input: DeleteProgramMembershipInput!
    $condition: ModelProgramMembershipConditionInput
  ) {
    deleteProgramMembership(input: $input, condition: $condition) {
      id
      rewardProgramId
      userId
      status
      pointBalance
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      onboarded
      createdAt
      updatedAt
      rewardPrograms {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      onboarded
      createdAt
      updatedAt
      rewardPrograms {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      onboarded
      createdAt
      updatedAt
      rewardPrograms {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createSearchCriteria = /* GraphQL */ `
  mutation CreateSearchCriteria(
    $input: CreateSearchCriteriaInput!
    $condition: ModelSearchCriteriaConditionInput
  ) {
    createSearchCriteria(input: $input, condition: $condition) {
      id
      userId
      rewardProgramId
      searchDate
      departureDate
      returnDate
      origin
      originType
      destination
      destinationType
      flightType
      cabinClass
      passengers {
        id
        age
      }
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateSearchCriteria = /* GraphQL */ `
  mutation UpdateSearchCriteria(
    $input: UpdateSearchCriteriaInput!
    $condition: ModelSearchCriteriaConditionInput
  ) {
    updateSearchCriteria(input: $input, condition: $condition) {
      id
      userId
      rewardProgramId
      searchDate
      departureDate
      returnDate
      origin
      originType
      destination
      destinationType
      flightType
      cabinClass
      passengers {
        id
        age
      }
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteSearchCriteria = /* GraphQL */ `
  mutation DeleteSearchCriteria(
    $input: DeleteSearchCriteriaInput!
    $condition: ModelSearchCriteriaConditionInput
  ) {
    deleteSearchCriteria(input: $input, condition: $condition) {
      id
      userId
      rewardProgramId
      searchDate
      departureDate
      returnDate
      origin
      originType
      destination
      destinationType
      flightType
      cabinClass
      passengers {
        id
        age
      }
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateDiggerDepartureAsyncRequest = /* GraphQL */ `
  mutation UpdateDiggerDepartureAsyncRequest(
    $input: UpdateDiggerDepartureAsyncRequestInput!
    $condition: ModelDiggerDepartureAsyncRequestConditionInput
  ) {
    updateDiggerDepartureAsyncRequest(input: $input, condition: $condition) {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiggerDepartureAsyncRequest = /* GraphQL */ `
  mutation DeleteDiggerDepartureAsyncRequest(
    $input: DeleteDiggerDepartureAsyncRequestInput!
    $condition: ModelDiggerDepartureAsyncRequestConditionInput
  ) {
    deleteDiggerDepartureAsyncRequest(input: $input, condition: $condition) {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const updateDiggerDepartureAsyncResponse = /* GraphQL */ `
  mutation UpdateDiggerDepartureAsyncResponse(
    $input: UpdateDiggerDepartureAsyncResponseInput!
    $condition: ModelDiggerDepartureAsyncResponseConditionInput
  ) {
    updateDiggerDepartureAsyncResponse(input: $input, condition: $condition) {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiggerDepartureAsyncResponse = /* GraphQL */ `
  mutation DeleteDiggerDepartureAsyncResponse(
    $input: DeleteDiggerDepartureAsyncResponseInput!
    $condition: ModelDiggerDepartureAsyncResponseConditionInput
  ) {
    deleteDiggerDepartureAsyncResponse(input: $input, condition: $condition) {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDiggerReturnAsyncRequest = /* GraphQL */ `
  mutation UpdateDiggerReturnAsyncRequest(
    $input: UpdateDiggerReturnAsyncRequestInput!
    $condition: ModelDiggerReturnAsyncRequestConditionInput
  ) {
    updateDiggerReturnAsyncRequest(input: $input, condition: $condition) {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      session_id
      solution_set
      solution_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiggerReturnAsyncRequest = /* GraphQL */ `
  mutation DeleteDiggerReturnAsyncRequest(
    $input: DeleteDiggerReturnAsyncRequestInput!
    $condition: ModelDiggerReturnAsyncRequestConditionInput
  ) {
    deleteDiggerReturnAsyncRequest(input: $input, condition: $condition) {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      session_id
      solution_set
      solution_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const updateDiggerReturnAsyncResponse = /* GraphQL */ `
  mutation UpdateDiggerReturnAsyncResponse(
    $input: UpdateDiggerReturnAsyncResponseInput!
    $condition: ModelDiggerReturnAsyncResponseConditionInput
  ) {
    updateDiggerReturnAsyncResponse(input: $input, condition: $condition) {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiggerReturnAsyncResponse = /* GraphQL */ `
  mutation DeleteDiggerReturnAsyncResponse(
    $input: DeleteDiggerReturnAsyncResponseInput!
    $condition: ModelDiggerReturnAsyncResponseConditionInput
  ) {
    deleteDiggerReturnAsyncResponse(input: $input, condition: $condition) {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDiggerDepartureAsyncRequest = /* GraphQL */ `
  mutation CreateDiggerDepartureAsyncRequest(
    $input: CreateDiggerDepartureAsyncRequestInput!
    $condition: ModelDiggerDepartureAsyncRequestConditionInput
  ) {
    createDiggerDepartureAsyncRequest(input: $input, condition: $condition) {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const createDiggerDepartureAsyncResponse = /* GraphQL */ `
  mutation CreateDiggerDepartureAsyncResponse(
    $input: CreateDiggerDepartureAsyncResponseInput!
    $condition: ModelDiggerDepartureAsyncResponseConditionInput
  ) {
    createDiggerDepartureAsyncResponse(input: $input, condition: $condition) {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDiggerReturnAsyncRequest = /* GraphQL */ `
  mutation CreateDiggerReturnAsyncRequest(
    $input: CreateDiggerReturnAsyncRequestInput!
    $condition: ModelDiggerReturnAsyncRequestConditionInput
  ) {
    createDiggerReturnAsyncRequest(input: $input, condition: $condition) {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      session_id
      solution_set
      solution_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const createDiggerReturnAsyncResponse = /* GraphQL */ `
  mutation CreateDiggerReturnAsyncResponse(
    $input: CreateDiggerReturnAsyncResponseInput!
    $condition: ModelDiggerReturnAsyncResponseConditionInput
  ) {
    createDiggerReturnAsyncResponse(input: $input, condition: $condition) {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
