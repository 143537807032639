import './styles.scss';

import React from 'react';

type CardSliderProps = {
  children: React.ReactChild[];
  onLastClick: Function;
  finalCta: string;
}

export const CardSlider: React.FunctionComponent<CardSliderProps> = ({ onLastClick, finalCta, children }) => {

    let [current, setCurrent] = React.useState(0);

    const isLast = () => {
        return current === children.length - 1;
    }

    const handleClick = () => {
        isLast()
            ? onLastClick()
            : setCurrent(current + 1);
    }

    return (
        <div className="card-slider">
            <div className="card-container">
                {children[current]}
            </div>
            <div className="footer">
                <button onClick={handleClick}>
                    { isLast()
                        ? finalCta
                        : "Next"
                    }
                </button>
                <div className="indicator">
                    {
                        children.map( (_child, index) => {
                            return index <= current
                                ? <div key={index} className="circle filled"/>
                                : <div key={index} className="circle"/>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
