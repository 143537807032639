import { AddMembership, ADD_MEMBERSHIP, RemoveMembership, REMOVE_MEMBERSHIP, StoreMemberships, STORE_MEMBERSHIPS } from './types';
import { ProgramMembership } from '../../generated/graphql';

export function storeMemberships( programs: ProgramMembership[]): StoreMemberships {
    return {
        type: STORE_MEMBERSHIPS,
        payload: programs
    }
};


export function addMembership( program: ProgramMembership): AddMembership{
    return {
        type: ADD_MEMBERSHIP,
        payload: program
    }
};

export function removeMembership( program: ProgramMembership): RemoveMembership{
    return {
        type: REMOVE_MEMBERSHIP,
        payload: program
    }
};