import React from 'react';
import { logos } from '../../constants/logos';
import * as ICON from '../../assets/icons';
import { API } from 'aws-amplify';
import { AuthUtil } from '../../util/AuthUtil';
import { deleteProgramMembership } from '../../graphql/mutations';

type MembershipProps = {
    programName: string;
    membershipId: string;
}

const removeMembership = async (membershipId: string) => {
    let authMode = await AuthUtil.determineAuthMode();
    
    API.graphql({
        authMode,
        query: deleteProgramMembership,
        variables: {
            input: {
                id: membershipId
            }
        }
    });
}

export const MembershipRow: React.FunctionComponent<MembershipProps> = ({programName, membershipId}: MembershipProps) => {
    return (
        <div className="membership">
            <img src={logos[programName.toLowerCase()]} alt={`${programName.toLowerCase()}-logo`}/>
            <div>{programName}</div>
            <button onClick={() => removeMembership(membershipId)}><ICON.x className="x"/></button>
        </div>
    );
}