import { Reducer } from 'redux';
import { ADD_MEMBERSHIP, MembershipAction, REMOVE_MEMBERSHIP, STORE_MEMBERSHIPS } from "./types";
import { ProgramMembership } from '../../generated/graphql';

let initialState: ProgramMembership[] = [];

export const membershipsReducer: Reducer<ProgramMembership[], MembershipAction> = (
    state = initialState, 
    action: MembershipAction
): ProgramMembership[] => {
    switch (action.type) {
        case STORE_MEMBERSHIPS:
            return action.payload;
        case ADD_MEMBERSHIP:
            return [...state, action.payload];
        case REMOVE_MEMBERSHIP:
            return state.filter(membership => (membership.id !== action.payload.id));
        default:
            return state;
    }
};