/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSearchCriteria = /* GraphQL */ `
  subscription OnCreateSearchCriteria {
    onCreateSearchCriteria {
      id
      userId
      rewardProgramId
      searchDate
      departureDate
      returnDate
      origin
      originType
      destination
      destinationType
      flightType
      cabinClass
      passengers {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        age
      }
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSearchCriteria = /* GraphQL */ `
  subscription OnUpdateSearchCriteria {
    onUpdateSearchCriteria {
      id
      userId
      rewardProgramId
      searchDate
      departureDate
      returnDate
      origin
      originType
      destination
      destinationType
      flightType
      cabinClass
      passengers {
        id
        age
      }
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSearchCriteria = /* GraphQL */ `
  subscription OnDeleteSearchCriteria {
    onDeleteSearchCriteria {
      id
      userId
      rewardProgramId
      searchDate
      departureDate
      returnDate
      origin
      originType
      destination
      destinationType
      flightType
      cabinClass
      passengers {
        id
        age
      }
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateDiggerDepartureAsyncRequest = /* GraphQL */ `
  subscription OnCreateDiggerDepartureAsyncRequest {
    onCreateDiggerDepartureAsyncRequest {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDiggerDepartureAsyncRequest = /* GraphQL */ `
  subscription OnUpdateDiggerDepartureAsyncRequest {
    onUpdateDiggerDepartureAsyncRequest {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDiggerDepartureAsyncRequest = /* GraphQL */ `
  subscription OnDeleteDiggerDepartureAsyncRequest {
    onDeleteDiggerDepartureAsyncRequest {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDiggerReturnAsyncRequest = /* GraphQL */ `
  subscription OnCreateDiggerReturnAsyncRequest {
    onCreateDiggerReturnAsyncRequest {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      session_id
      solution_set
      solution_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDiggerReturnAsyncRequest = /* GraphQL */ `
  subscription OnUpdateDiggerReturnAsyncRequest {
    onUpdateDiggerReturnAsyncRequest {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      session_id
      solution_set
      solution_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDiggerReturnAsyncRequest = /* GraphQL */ `
  subscription OnDeleteDiggerReturnAsyncRequest {
    onDeleteDiggerReturnAsyncRequest {
      id
      triptype
      origin
      destination
      departuredate
      returndate
      passengers
      session_id
      solution_set
      solution_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRewardProgram = /* GraphQL */ `
  subscription OnCreateRewardProgram {
    onCreateRewardProgram {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRewardProgram = /* GraphQL */ `
  subscription OnUpdateRewardProgram {
    onUpdateRewardProgram {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRewardProgram = /* GraphQL */ `
  subscription OnDeleteRewardProgram {
    onDeleteRewardProgram {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      memberships {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateProgramPartner = /* GraphQL */ `
  subscription OnCreateProgramPartner {
    onCreateProgramPartner {
      id
      rewardProgramId
      partnerId
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      partner {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateProgramPartner = /* GraphQL */ `
  subscription OnUpdateProgramPartner {
    onUpdateProgramPartner {
      id
      rewardProgramId
      partnerId
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      partner {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteProgramPartner = /* GraphQL */ `
  subscription OnDeleteProgramPartner {
    onDeleteProgramPartner {
      id
      rewardProgramId
      partnerId
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      partner {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateProvider = /* GraphQL */ `
  subscription OnCreateProvider {
    onCreateProvider {
      id
      name
      type
      createdAt
      updatedAt
      partnerPrograms {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateProvider = /* GraphQL */ `
  subscription OnUpdateProvider {
    onUpdateProvider {
      id
      name
      type
      createdAt
      updatedAt
      partnerPrograms {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteProvider = /* GraphQL */ `
  subscription OnDeleteProvider {
    onDeleteProvider {
      id
      name
      type
      createdAt
      updatedAt
      partnerPrograms {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          partner {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateProgramMembership = /* GraphQL */ `
  subscription OnCreateProgramMembership {
    onCreateProgramMembership {
      id
      rewardProgramId
      userId
      status
      pointBalance
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateProgramMembership = /* GraphQL */ `
  subscription OnUpdateProgramMembership {
    onUpdateProgramMembership {
      id
      rewardProgramId
      userId
      status
      pointBalance
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteProgramMembership = /* GraphQL */ `
  subscription OnDeleteProgramMembership {
    onDeleteProgramMembership {
      id
      rewardProgramId
      userId
      status
      pointBalance
      createdAt
      updatedAt
      rewardProgram {
        id
        name
        providerId
        baseRate
        additionalInfo
        createdAt
        updatedAt
        partners {
          items {
            id
            rewardProgramId
            partnerId
            createdAt
            updatedAt
          }
          nextToken
        }
        provider {
          id
          name
          type
          createdAt
          updatedAt
          partnerPrograms {
            nextToken
          }
        }
        memberships {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        birthDate
        addressLine1
        addressLine2
        city
        state
        zipcode
        onboarded
        createdAt
        updatedAt
        rewardPrograms {
          items {
            id
            rewardProgramId
            userId
            status
            pointBalance
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      onboarded
      createdAt
      updatedAt
      rewardPrograms {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      onboarded
      createdAt
      updatedAt
      rewardPrograms {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      onboarded
      createdAt
      updatedAt
      rewardPrograms {
        items {
          id
          rewardProgramId
          userId
          status
          pointBalance
          createdAt
          updatedAt
          rewardProgram {
            id
            name
            providerId
            baseRate
            additionalInfo
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            addressLine1
            addressLine2
            city
            state
            zipcode
            onboarded
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateDiggerDepartureAsyncResponse = /* GraphQL */ `
  subscription OnCreateDiggerDepartureAsyncResponse {
    onCreateDiggerDepartureAsyncResponse {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDiggerDepartureAsyncResponse = /* GraphQL */ `
  subscription OnUpdateDiggerDepartureAsyncResponse {
    onUpdateDiggerDepartureAsyncResponse {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDiggerDepartureAsyncResponse = /* GraphQL */ `
  subscription OnDeleteDiggerDepartureAsyncResponse {
    onDeleteDiggerDepartureAsyncResponse {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDiggerReturnAsyncResponse = /* GraphQL */ `
  subscription OnCreateDiggerReturnAsyncResponse {
    onCreateDiggerReturnAsyncResponse {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDiggerReturnAsyncResponse = /* GraphQL */ `
  subscription OnUpdateDiggerReturnAsyncResponse {
    onUpdateDiggerReturnAsyncResponse {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDiggerReturnAsyncResponse = /* GraphQL */ `
  subscription OnDeleteDiggerReturnAsyncResponse {
    onDeleteDiggerReturnAsyncResponse {
      id
      requestId
      response {
        _id
        item {
          flight {
            flight_arrival_time
            flight_depart_time
            session_id
            departing_airport_code
            airline
            departing_airport
            arriving_airport
            arriving_airport_code
            flight_duration
            solution_set
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
