import { Action } from 'redux';
import { ProgramMembership } from '../../generated/graphql';

export const STORE_MEMBERSHIPS = "STORE_MEMBERSHIPS";
export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP";
export const REMOVE_MEMBERSHIP = "REMOVE_MEMBERSHIP";

// action type
export interface StoreMemberships extends Action<"STORE_MEMBERSHIPS"> {
    payload: ProgramMembership[]
};

export interface AddMembership extends Action<"ADD_MEMBERSHIP"> {
    payload: ProgramMembership
};

export interface RemoveMembership extends Action<"REMOVE_MEMBERSHIP"> {
    payload: ProgramMembership
};

export type MembershipAction = StoreMemberships | AddMembership | RemoveMembership;