
/*queries */
export const listRewardPrograms_NoMemberships = /* GraphQL */ `
query ListRewardPrograms(
  $filter: ModelRewardProgramFilterInput
  $limit: Int
  $nextToken: String
) {
  listRewardPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;

export const listProgramMemberships_ForUser = /* GraphQL */ `
  query ListProgramMemberships(
    $filter: ModelProgramMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramMemberships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rewardProgramId
        userId
        status
        pointBalance
        createdAt
        updatedAt
        rewardProgram {
          id
          name
          providerId
          baseRate
          additionalInfo
          createdAt
          updatedAt
          partners {
            nextToken
          }
          provider {
            id
            name
            type
            createdAt
            updatedAt
          }
        }
        user {
          id
          firstName
          lastName
          email
          birthDate
          addressLine1
          addressLine2
          city
          state
          zipcode
          rewardPrograms {
            nextToken
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

/* mutations */
export const createProgramMembership_NoMemberships = /* GraphQL */ `
mutation CreateProgramMembership(
  $input: CreateProgramMembershipInput!
  $condition: ModelProgramMembershipConditionInput
) {
  createProgramMembership(input: $input, condition: $condition) {
    id
    rewardProgramId
    userId
    status
    pointBalance
    createdAt
    updatedAt
    rewardProgram {
      id
      name
      providerId
      baseRate
      additionalInfo
      createdAt
      updatedAt
      partners {
        items {
          id
          rewardProgramId
          partnerId
          createdAt
          updatedAt
        }
        nextToken
      }
      provider {
        id
        name
        type
        createdAt
        updatedAt
        partnerPrograms {
          nextToken
        }
      }
    }
    user {
      id
      firstName
      lastName
      email
      birthDate
      addressLine1
      addressLine2
      city
      state
      zipcode
      createdAt
      updatedAt
    }
  }
}
`;