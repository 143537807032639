import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

// common styles
import '../../assets/common.module.scss';
import logo from '../../assets/lr-logo.png';
import './style.scss';

// LR Components
import { AppState } from './../../store/types';
import Store from './../../store';
import { LoyaltyRoverTheme } from '../../amplify/LoyaltyRoverTheme';
import { FederationConfig } from '../../amplify/FederationConfig';

// Amplify
import Amplify from 'aws-amplify';
import { Authenticator, Greetings } from "aws-amplify-react";
import awsconfig from '../../aws-exports';
import { UsernameAttributes } from 'aws-amplify-react/lib-esm/Auth/common/types';
import { recordAuthState } from '../../store/auth';
import { LoyaltyRouter } from '../router';

Amplify.configure(awsconfig);

const App: FunctionComponent<any> = (props: any) => {
  return (
    <> 
    <div id="header">
      <img src={logo} alt="logo" />
    </div>
    <Authenticator
      federated={FederationConfig}
      theme={LoyaltyRoverTheme}
      amplifyConfig={awsconfig}
      hide={[Greetings]}
      usernameAttributes={UsernameAttributes.EMAIL}
      onStateChange={(authState: string) => (Store.dispatch(recordAuthState(authState)))}
    >
      <LoyaltyRouter { ...props }/>
    </Authenticator>
    </>
  );
}

export default connect(
  // map state to props
  (state: AppState) => ({ ...state }),
  // map dispatch to props
  (dispatch: Function): any => ({
    // storeAirports: () => dispatch(thunkStoreAirports()),
    // submitSearch: (searchCriteria: SearchCriteria) => 
    //   dispatch(submitSearchCriteria(searchCriteria)),
  })
)(App);