import { Action } from 'redux';
import { RewardProgram } from '../../generated/graphql';

export const STORE_PROGRAMS = "STORE_PROGRAMS";

// action type
export interface StorePrograms extends Action<"STORE_PROGRAMS"> {
    payload: RewardProgram[]
};

export type ProgramAction = StorePrograms; // ...