import React from 'react';
import { API } from "aws-amplify";
import { AuthUtil } from "./../../util/AuthUtil";

import { createProgramMembership_NoMemberships } from '../../graphql/queries_partial';
import { logos } from '../../constants/logos';

const addProgram = async (programId: string) => {
    let authMode = await AuthUtil.determineAuthMode();
    let user = await AuthUtil.determineCurrentUser();

    let res = await API.graphql({
        authMode,
        query: createProgramMembership_NoMemberships,
        variables: {
            input: {
                rewardProgramId: programId,
                userId: user.username
            }
        }
    });

    return res;
}

export const ProgramOption: React.FunctionComponent = (props: any) => {

    const logo = logos[props.label.toLowerCase()]

    return (
        <div className="option">
            <img src={logo} alt={`${props.label.toLowerCase()}-logo`}/>
            <button onClick={()=> addProgram(props.value)}>Add</button>
        </div>
    )
}