import './account-info.scss';
import React from 'react';
import * as routes from './../../constants/routes';
import { Auth } from 'aws-amplify';

export const AccountInfo: React.FunctionComponent = (props: any) => {

    let { user } = props.auth;

    return (
        <div className="account-info">
            <section>
                <div className="title">Account Settings</div>
                <div className="row">
                    <label>Email</label>
                    <div>{user && (user.email || user.attributes.email)}</div>
                </div>
                <div className="row">
                    <a href="#" onClick={user
                    ? () => {
                        Auth.signOut();
                        // @ts-ignore
                        window.location="/"
                      } 
                    : () => {}} >Logout</a>
                </div>
            </section>
            <section>
                <div className="title">About Us</div>
                <div className="row">
                    <a href={routes.EXTERNAL.TERMS_OF_SERVICE}>
                        Legal
                    </a>
                </div>
                <div className="row">
                    <a href={routes.EXTERNAL.MAILTO_SUPPORT}>
                        Support
                    </a>
                </div>
                <div className="row">
                    <a href={routes.EXTERNAL.CHROME_STORE}>
                        Rate Loyalty Rover
                    </a>
                </div>
            </section>
        </div>
    )
}