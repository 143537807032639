import { 
    Store, 
    createStore, 
    applyMiddleware, 
    combineReducers 
} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { AppState } from './types';
import rootSaga from '../sagas';
import { authReducer } from './auth';
import { programsReducer } from './programs';
import { membershipsReducer } from './memberships';

const rootReducer = combineReducers<AppState>({
    auth: authReducer,
    memberships: membershipsReducer,
    programs: programsReducer
});

const sagaMiddleware = createSagaMiddleware();

const store: Store<AppState> = createStore(
    rootReducer,
    applyMiddleware(thunk, sagaMiddleware)
);

sagaMiddleware.run(rootSaga)

export default store;