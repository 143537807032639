import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Account } from '../../components/account';
import { Onboard } from '../../components/onboard';
import * as routes from './../../constants/routes';
import Store from './../../store';

export const LoyaltyRouter = (props: any): React.ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        { props.authState === "signIn" &&
            <Redirect strict exact push from={routes.ALL} to={routes.ROOT} />
        }
        { props.authState === "signedIn" &&
            <Redirect strict exact push from={routes.ROOT}  to={routes.ONBOARD}/>
        }
        <Route exact path={routes.ONBOARD} 
          render={(props) => (
            <Onboard {...props} {...(Store.getState())}/>
          )} 
        />
        <Route exact path={routes.ACCOUNT} 
          render={(props) => (
            <Account {...props} {...(Store.getState())}/>
          )} 
        />
      </Switch>
    </BrowserRouter>
  );
};