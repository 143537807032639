import './styles.scss';
import React from 'react';
import * as ICON from './../../assets/icons';
import { CardSlider } from '../cardSlider';
import { useHistory } from 'react-router-dom';
import * as routes from './../../constants/routes';
import { PseudoModal } from '../../containers/pseudoModal';

const Card: React.FunctionComponent<any> = ({ icons, text }) => {
    return (
        <div className="card">
            <div className="icons">
                {icons}
            </div>
            <div className="text">
                <span>{text}</span>
            </div>
        </div>
    );
}

export const Onboard: React.FunctionComponent<any> = (props: any) => {

    let history = useHistory();

    const goToAccount = () => {
        history.push(routes.ACCOUNT);
    };

    return (
        <PseudoModal>
            <CardSlider onLastClick={goToAccount} finalCta="Get Started">
                <Card 
                    icons={[<ICON.search key="search" />, <ICON.departure key="departure" />]} 
                    text="Search for paid fares or rates on your favorite travel websites"
                    />
                <Card 
                    icons={[<ICON.tag key="tag" />, <ICON.bill key="bill" />]} 
                    text={<>When the Loyalty Rover icon is <b>highlighted</b>, Rover will automatically fetch matching reward fares</>}
                    />
                <Card
                    icons={[<ICON.plane key="plane" />, <ICON.smile key="smile" />]}
                    text={<>Compare paid and reward prices side by side, so you can <b>redeem smarter</b>!</>}
                    />
            </CardSlider>
        </PseudoModal>
    );
}