import { Action } from 'redux';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

export const RECORD_AUTH_STATE = "RECORD_AUTH_STATE";
export const RECORD_AUTH_MODE = "RECORD_AUTH_MODE";
export const RECORD_AUTH_USER = "RECORD_AUTH_USER";

// action type
export interface RecordAuthState extends Action<"RECORD_AUTH_STATE"> {
    payload: String
};

export interface RecordAuthMode extends Action<"RECORD_AUTH_MODE"> {
    payload: String
};

export interface RecordAuthUser extends Action<"RECORD_AUTH_USER"> {
    payload: any
};

export type AuthAction = RecordAuthMode | RecordAuthState | RecordAuthUser;

export type AuthState= {
    state: String
    mode: GRAPHQL_AUTH_MODE
    user: any
}