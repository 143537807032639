import './styles.scss';
import React, { ReactChild } from 'react';

type TabsProps = {
    defaultTabIndex: number;
    tabNames: string[];
    children: ReactChild[];
}

export const Tabs: React.FunctionComponent<TabsProps> = ({ defaultTabIndex, tabNames, children }: TabsProps) => {

    const [currentTabIndex, setCurrentTabIndex] = React.useState(defaultTabIndex);

    if(tabNames.length !== children.length) {
        return <div>tabNames and children must be arryas of the same length</div>
    }

    return (
        <div className="tabs-container">
            <div className="tabs-navigation">
                {
                    tabNames.map((tabName, index) => 
                        (<div 
                            key={index} 
                            className={currentTabIndex === index? "current" : ""} 
                            onClick={() => setCurrentTabIndex(index)}
                            >
                                {tabName}
                        </div>))
                }
            </div>
            <div className="tab-display">
                { children.filter((_child, index) => (index === currentTabIndex)) }
            </div>
        </div>
    );
}