
import React, { ReactChild } from "react";
import { Container, FormSection } from "aws-amplify-react";
import headerLogo from './../../assets/logo-header-black-text.png';
import variables from '../../assets/variables.module.scss';
import './styles.scss';

export type PseudoModalProps = {
    children: ReactChild | ReactChild[];
}

export const PseudoModal: React.FunctionComponent<PseudoModalProps> = ({ children }: PseudoModalProps) => {
    return (
        <Container>
            <FormSection style={{
                padding: "20px 0px 20px",
                margin: "0",
                minHeight: "500px",
                minWidth: "500px",
                fontFamily: variables.fontFamily
                }}>
                <div className="header">
                    <div className="logo">
                        <img src={headerLogo} alt="header-logo" />
                    </div>
                </div>
                {children}
            </FormSection>
        </Container>
    )
}