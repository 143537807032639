import { API } from "aws-amplify";
import { AuthUtil } from "../util/AuthUtil";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import Store from './../store';
import { storePrograms } from "./programs";
import { addMembership, removeMembership, storeMemberships } from "./memberships";
import { listProgramMemberships_ForUser, listRewardPrograms_NoMemberships } from "../graphql/queries_partial";
import { onCreateProgramMembership, onDeleteProgramMembership } from "../graphql/subscriptions";

// TODO refactor to use an INIT action and sagas

const hydrate = async (authMode: GRAPHQL_AUTH_MODE) => {

    const { data: { listProgramMemberships : retrievedMemberships } } = await API.graphql({
        authMode,
        query: listProgramMemberships_ForUser
    });
    Store.dispatch(storeMemberships(retrievedMemberships.items));
    
    const { data: { listRewardPrograms : retrievedPrograms } } = await API.graphql({
        authMode,
        query: listRewardPrograms_NoMemberships
    });
    Store.dispatch(storePrograms(retrievedPrograms.items));
};

const subscribe = async (authMode: GRAPHQL_AUTH_MODE) => {
    await API.graphql({
        authMode,
        query: onCreateProgramMembership
    }).subscribe({
        next: (res: any) => {
            const newMembership = res.value.data.onCreateProgramMembership;
            Store.dispatch(addMembership(newMembership));
        }
    });

    await API.graphql({
        authMode,
        query: onDeleteProgramMembership
    }).subscribe({
        next: (res: any) => {
            const formerMembership = res.value.data.onDeleteProgramMembership;
            Store.dispatch(removeMembership(formerMembership));
        }
    });
};

let initStarted = false;

export const init = async () => {
    if(!initStarted) {
        initStarted = true;
        let authMode = await AuthUtil.determineAuthMode();
        await AuthUtil.determineCurrentUser();
    
        await hydrate(authMode);
        await subscribe(authMode);
    }
};