/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7eabae69-1bf5-4a33-8ce5-5f1a2f09d8e8",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_nUl2HRcsf",
    "aws_user_pools_web_client_id": "61fuqkth9trtcu7iod87ln16j3",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://wxtdcignh5aqnotrzoknzeyzie.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "loyaltyRoverExtensionAPI",
            "endpoint": "https://ggbdxj3jm8.execute-api.us-east-2.amazonaws.com/develop",
            "region": "us-east-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "duffleAirports-develop",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
