import React from 'react';
import { PseudoModal } from '../../containers/pseudoModal';
import { AccountInfo } from './AccountInfo';
import { UpdateProgramsForm } from '../accountUpdatePrograms';
import { Tabs } from '../tabs';

export const Account: React.FunctionComponent<any> = (props: any) => {

    const defaultTabIndex = props.memberships.length > 0? 0 : 1;    
    
    return (
        <PseudoModal>
            <Tabs defaultTabIndex={defaultTabIndex} tabNames={["Account","Programs"]}>
                <AccountInfo {...props} />
                <UpdateProgramsForm {...props} />
            </Tabs>
        </PseudoModal>
    );
}